import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./../App";
import { motion } from "framer-motion";
import { ThemeContext } from "../context/ThemeContext";

const MCExercise = ({ exerciseTitle }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [finished, setFinish] = useState();
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [unansweredQuestionsIndex, setUnansweredQuestionsIndex] = useState([]);
  const { theme } = useContext(ThemeContext);

  const handleBackToExercises = () => {
    navigate("/exercises", { state: { from: exerciseTitle } });
  };

  const handleUnansweredClick = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleOptionChange = (questionId, optionLabel) => {
    setAnswers({ ...answers, [questionId]: optionLabel });
    setUnansweredQuestionsIndex(function (unansweredQuestionIds) {
      const newUnansweredQuestionIds = unansweredQuestionIds.filter(
        (id) => id !== questionId,
      );
      if (newUnansweredQuestionIds.length === 0) {
        setFinish(true);
      }
      return newUnansweredQuestionIds;
    });

    setTimeout(() => {
      handleNext();
    }, 250);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/${exerciseTitle}`,
        );
        setQuestions(response.data);
        setUnansweredQuestionsIndex(response.data.map((q) => q.question_id));
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [exerciseTitle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const unanswered = questions
      .filter((question) => answers[question.question_id] === undefined)
      .map((question) => question.question_id);

    if (unanswered.length > 0) {
      setUnansweredQuestionsIndex(unanswered);
      setFinish(false);
      return;
    }
    setFinish(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/submit/${exerciseTitle}`,
        {
          user_answers: answers,
          user,
        },
      );
      navigate("/score", { state: response.data });
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  const formattedTitle = exerciseTitle
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  return (
    <div className="flex max-w-full flex-grow flex-col rounded-xl bg-gray-50 shadow-lg dark:bg-gray-900 sm:px-6 lg:px-8">
      {/* Header Section */}
      <div
        className={`${
          theme === "dark"
            ? "bg-gray-800 text-gray-100"
            : "bg-teal-500 text-white"
        } px-4 py-8 sm:px-6 lg:px-8`}
      >
        <button
          className={`mb-2 rounded-lg border px-2 py-1 text-sm font-semibold sm:px-4 sm:py-2 ${
            theme === "dark"
              ? "border-gray-600 text-gray-300 hover:bg-gray-700"
              : "border-teal-500 text-white hover:bg-teal-400"
          } md:text-xl lg:text-2xl`}
          onClick={handleBackToExercises}
        >
          Back to Exercises
        </button>
        <h1 className="mt-2 text-center text-2xl font-bold md:text-3xl lg:text-4xl">
          English Grammar Exercise: {formattedTitle}
        </h1>
      </div>

      {/* Content Section */}
      <div
        className={`flex-grow ${
          theme === "dark"
            ? "bg-gray-900 text-gray-200"
            : "bg-white text-gray-900"
        } px-4 py-6 sm:px-6 lg:px-8`}
      >
        {questions.length > 0 && (
          <motion.div
            key={currentQuestionIndex}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            className="mb-4"
          >
            <p
              className={`mb-1 font-semibold sm:mb-4 ${
                theme === "dark" ? "text-gray-300" : "text-gray-800"
              }`}
            >
              Question {currentQuestionIndex + 1} of {questions.length}
            </p>
            <p
              className={`mb-6 sm:mb-10 md:mb-12 ${
                theme === "dark" ? "text-gray-300" : "text-gray-700"
              } text-xl sm:text-2xl`}
            >
              {questions[currentQuestionIndex].question}
            </p>
            <ul className="flex w-full flex-wrap gap-4 text-red-500 sm:text-xl">
              {questions[currentQuestionIndex].options.map((option) => (
                <li
                  key={option.label}
                  className="min-w-full sm:min-w-[calc(50%-1rem)] sm:flex-1"
                >
                  <label
                    className={`flex h-full w-full items-center rounded-lg p-3 transition duration-150 ease-in-out sm:p-4 ${
                      theme === "dark"
                        ? "bg-gray-800 text-gray-300 hover:bg-gray-700"
                        : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                    }`}
                  >
                    <input
                      type="radio"
                      name={`question-${currentQuestionIndex}`}
                      value={option.label}
                      onChange={() =>
                        handleOptionChange(
                          questions[currentQuestionIndex].question_id,
                          option.label,
                        )
                      }
                      checked={
                        answers[questions[currentQuestionIndex].question_id] ===
                        option.label
                      }
                      className="form-radio h-5 w-5 text-teal-500 transition duration-150 ease-in-out"
                    />
                    <span className="ml-3">{`${option.label}. ${option.text}`}</span>
                  </label>
                </li>
              ))}
            </ul>
          </motion.div>
        )}

        {finished === false && unansweredQuestionsIndex.length > 0 && (
          <div
            className={`mt-4 rounded border-l-4 p-3 ${
              theme === "dark"
                ? "border-yellow-500 bg-yellow-200 text-yellow-900"
                : "border-red-500 bg-red-100 text-red-700"
            }`}
          >
            <p>Please answer all questions before submitting.</p>
            <ul className="mt-2 list-inside list-disc">
              {unansweredQuestionsIndex.map((qId) => (
                <li
                  key={qId}
                  className={`cursor-pointer ${
                    theme === "dark" ? "text-teal-400" : "text-blue-600"
                  } hover:underline`}
                  onClick={() =>
                    handleUnansweredClick(
                      questions.findIndex((q) => q.question_id === qId),
                    )
                  }
                >
                  Question {qId}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Navigation Buttons Section */}
      <div
        className={`px-4 py-3 shadow-lg sm:px-6 lg:px-8 ${
          theme === "dark"
            ? "bg-gray-800 text-gray-200"
            : "bg-gray-50 text-gray-800"
        }`}
      >
        <div className="flex justify-between">
          <button
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
            className={`rounded-lg px-4 py-2 text-sm font-semibold transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50${
              theme === "dark"
                ? "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-gray-500 disabled:opacity-50"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400 focus:ring-gray-500 disabled:opacity-50"
            }`}
          >
            Previous
          </button>
          {currentQuestionIndex === questions.length - 1 ? (
            <button
              onClick={handleSubmit}
              className={`rounded-lg px-6 py-2 text-sm font-semibold text-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                theme === "dark"
                  ? "bg-teal-500 hover:bg-teal-600 focus:ring-teal-500"
                  : "bg-teal-500 hover:bg-teal-600 focus:ring-teal-500"
              }`}
            >
              Submit
            </button>
          ) : (
            <button
              onClick={handleNext}
              className={`rounded-lg px-4 py-2 text-sm font-semibold text-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                theme === "dark"
                  ? "bg-teal-500 hover:bg-teal-600 focus:ring-teal-500"
                  : "bg-teal-500 hover:bg-teal-600 focus:ring-teal-500"
              }`}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MCExercise;
