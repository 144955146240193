import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { mainTopics } from "./topics";
import { UserContext } from "../App";

const Exercises = () => {
  const { isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {};
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [availableTopics, setAvailableTopics] = useState([]);
  const [isCurrentSubtopicAvailable, setIsCurrentSubtopicAvailable] =
    useState(false);
  const [isAnySubtopicSelected, setIsAnySubtopicSelected] = useState(false);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        
        const response = await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/titles`);
        setAvailableTopics(response.data.uniqueTitles);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, []);

  useEffect(() => {
    if (from) {
      const matchingTopic = mainTopics.find((topic) =>
        topic.subtopics.includes(from)
      );
      if (matchingTopic) {
        handleTopicSelection(matchingTopic);
      }
    }
  }, [from]);

  const handleSubTopicSelection = (subtopic) => {
    setIsAnySubtopicSelected(true);
    if (availableTopics.includes(subtopic)) {
      setIsCurrentSubtopicAvailable(true);
      handleNavigateToSubtopic(subtopic);
    } else {
      setIsCurrentSubtopicAvailable(false);
    }
  };

  const handleTopicSelection = (topic) => {
    setSelectedTopic(topic);
    setIsAnySubtopicSelected(false);
    setIsCurrentSubtopicAvailable(false);
    localStorage.setItem("lastSelectedTopic", JSON.stringify(topic));
  };

  const handleBackToMainTopics = () => {
    setSelectedTopic(null);
    setIsAnySubtopicSelected(false);
    setIsCurrentSubtopicAvailable(false);
  };

  const handleNavigateToSubtopic = (subtopic) => {
    navigate(`/${subtopic}`);
    localStorage.setItem("currentSubTopic", subtopic);
    setIsCurrentSubtopicAvailable(true);
  };

  if (!isAuthenticated) {
    return <Navigate to="/reject-access" />;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-customDark p-6 flex flex-col">
      {/* Header */}
      <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-lightGray mb-8">
        Identify and Correct Errors
      </h1>
      <div className="flex flex-col md:flex-row gap-6 flex-1">
        {/* Topics List */}
        <div className={`flex-1 ${selectedTopic && "hidden md:block"}`}>
          {mainTopics.map((topic) => (
            <div
              key={topic.name}
              onClick={() => handleTopicSelection(topic)}
              className="p-6 bg-white dark:bg-veryDark shadow-lg rounded-lg mb-6 cursor-pointer transition-transform transform hover:scale-105 hover:shadow-xl"
            >
              <span className="font-semibold text-gray-800 dark:text-lightGray text-xl">
                {topic.name}
              </span>
            </div>
          ))}
        </div>

        {/* Subtopics or Feedback */}
        {(selectedTopic || window.innerWidth >= 768) && (
          <div className="flex-1">
            {selectedTopic && (
              <button
                onClick={handleBackToMainTopics}
                className="text-indigo-500 hover:text-indigo-600 mb-6 md:hidden flex items-center"
              >
                ← Back to Topics
              </button>
            )}

            {isAnySubtopicSelected && !isCurrentSubtopicAvailable ? (
              <div className="p-6 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 rounded-lg shadow-md flex items-center justify-center h-32">
                <span className="font-semibold text-lg text-center">
                  This exercise is not available yet.
                </span>
              </div>
            ) : (
              selectedTopic?.subtopics.map((subtopic) => (
                <div
                  key={subtopic}
                  onClick={() => handleSubTopicSelection(subtopic)}
                  className="p-6 bg-white dark:bg-veryDark shadow-lg rounded-lg mb-6 cursor-pointer transition-transform transform hover:scale-105 hover:shadow-xl flex items-center"
                >
                  <span className="font-medium text-gray-800 dark:text-lightGray text-lg">
                    {subtopic
                      .split("-")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </span>
                  <svg
                    className="ml-auto w-6 h-6 text-gray-500 dark:text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Exercises;
