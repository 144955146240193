export const mainTopics = [
  {
    name: "Tenses and Aspects",
    subtopics: ["present-tense", "past-tense", "future-tense"],
  },
  {
    name: "Agreement",
    subtopics: ["Agreement with collective nouns"],
  },
  {
    name: "Sentence Structure",
    subtopics: ["sentence-fragment"],
  },
];
