import { useState, useEffect } from "react";
import "./SignUpWindow.css";
import { useNavigate } from "react-router-dom";

const SignUpWindow = ({ closeSignUp, onLogin, setUser }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user_name, setUserName] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [IsValidSignUp, setIsValidSignUp] = useState();
  const [SignUpFailedMessage, setSignUpFailedMessage] = useState();
  const [countdown, setCountdown] = useState(3); // Countdown starting from 3 seconds

  useEffect(() => {
    let timer;
    if (IsValidSignUp && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (IsValidSignUp && countdown === 0) {
      navigate("/dashboard");
      closeSignUp();
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [IsValidSignUp, countdown]);

  const handleSignUp = async (event) => {
    event.preventDefault(); // Prevent the default form submit action

    // Send a POST request to the server
    const response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: user_name,
        user_email: email,
        password: password,
        confirm_password: confirm_password,
      }), // Adjust these fields based on your backend expectations
    });
    const data = await response.json();
    if ((response.status === 200) | (response.status === 201)) {
      onLogin();
      setUser({ email: data.user_email, name: data.user_name });
      localStorage.setItem("userEmail", data.user_email);
      localStorage.setItem("userName", data.user_name);
      setIsValidSignUp(true);
      setTimeout(() => {
        navigate("/dashboard");
        closeSignUp();
      }, 3000);
    } else {
      setIsValidSignUp(false);
      setSignUpFailedMessage(data.message);
      // alert(data.message);
    }
  };
  return (
    <div className="signup-div">
      {!IsValidSignUp && SignUpFailedMessage && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 display-login-reject"
          role="alert"
        >
          <h1 className="font-semibold text-lg">{SignUpFailedMessage}</h1>
        </div>
      )}
      {IsValidSignUp && (
        <div
          className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4 rounded shadow-md"
          role="alert"
        >
          <h1 className="font-semibold text-lg">
            SignUp successful! <br />
            Directing you to the dashboard in {countdown} seconds...
          </h1>
        </div>
      )}
      <div className="flex items-center w-[100%]">
        <div className="signup-label grow text-center">Sign Up</div>
        <button className="leave-signup text-4xl" onClick={closeSignUp}>
          &times;
        </button>
      </div>
      <form className="form-div" onSubmit={handleSignUp}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={user_name.trim()}
          onChange={(e) => setUserName(e.target.value.trim())}
          className="border-black border-2"
          required
        />
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border-black border-2"
          required
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          className="border-black border-2"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label htmlFor="confirm-password">Confirm Password:</label>
        <input
          type="password"
          id="confirm-password"
          name="confirm-password"
          value={confirm_password}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="border-black border-2"
          required
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-300"
        >
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignUpWindow;
