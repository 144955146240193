import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const HomePage = () => {
  return (
    <div className="flex min-h-screen flex-col justify-between bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <header className="py-16 text-center sm:py-20">
          <img
            src={logo}
            alt="Language Explorer"
            className="mx-auto h-16 w-auto sm:h-20"
          />
          <h1 className="mt-4 text-3xl font-bold text-gray-800 dark:text-gray-200 sm:text-4xl md:text-5xl">
            Master Your Grammar with Language Explorer
          </h1>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-400 sm:text-xl">
            Boost your grammar through interactive learning
          </p>
          <Link
            to="/exercises"
            className="mt-8 inline-block rounded-full bg-blue-600 px-6 py-2 text-base font-semibold text-white transition duration-300 hover:bg-blue-700 sm:px-8 sm:py-3 sm:text-lg"
          >
            Start Your Journey
          </Link>
        </header>

        {/* Social Proof */}
        <div className="mb-12 flex flex-col items-center justify-center space-y-8 text-center sm:mb-16 sm:flex-row sm:space-x-12 sm:space-y-0">
          <div>
            <h3 className="text-2xl font-bold text-blue-600 sm:text-3xl">
              10,000+
            </h3>
            <p className="text-gray-600 dark:text-gray-400">Active Learners</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-blue-600 sm:text-3xl">
              50,000+
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              Exercises Completed
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-blue-600 sm:text-3xl">
              4.8/5
            </h3>
            <p className="text-gray-600 dark:text-gray-400">User Rating</p>
          </div>
        </div>

        {/* Features Section */}
        <div className="mb-12 grid gap-8 sm:mb-16 sm:grid-cols-2 md:grid-cols-3">
          <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
              Diverse Exercise Types
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              From multiple-choice to regex challenges, we've got you covered.
            </p>
          </div>
          <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
              Interactive Learning
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Engage with dynamic exercises designed for maximum retention.
            </p>
          </div>
          <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
              Progress Tracking
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Monitor your growth with our intuitive dashboard.
            </p>
          </div>
        </div>

        {/* How it Works Section */}
        <div className="mb-12 text-center sm:mb-16">
          <h2 className="mb-6 text-2xl font-bold text-gray-800 dark:text-gray-200 sm:mb-8 sm:text-3xl">
            How Language Explorer Works
          </h2>
          <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-4">
            <div>
              <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-600 text-2xl font-bold text-white">
                1
              </div>
              <h3 className="text-lg font-semibold sm:text-xl">Sign Up</h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Create your free account
              </p>
            </div>
            <div>
              <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-600 text-2xl font-bold text-white">
                2
              </div>
              <h3 className="text-lg font-semibold sm:text-xl">
                Choose Topics
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Select your areas of interest
              </p>
            </div>
            <div>
              <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-600 text-2xl font-bold text-white">
                3
              </div>
              <h3 className="text-lg font-semibold sm:text-xl">Practice</h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Complete interactive exercises
              </p>
            </div>
            <div>
              <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-600 text-2xl font-bold text-white">
                4
              </div>
              <h3 className="text-lg font-semibold sm:text-xl">
                Track Progress
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Monitor your improvement
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials */}
        <div className="mb-12 text-center sm:mb-16">
          <h2 className="mb-6 text-2xl font-bold text-gray-800 dark:text-gray-200 sm:mb-8 sm:text-3xl">
            What Our Users Say
          </h2>
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
              <p className="text-gray-600 dark:text-gray-400">
                "Language Explorer has significantly improved my everyday
                communication skills. Highly recommended!"
              </p>
              <p className="mt-4 font-semibold">- Sarah K.</p>
            </div>
            <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
              <p className="text-gray-600 dark:text-gray-400">
                "The variety of exercises keeps me engaged, and I can see my
                progress improving daily."
              </p>
              <p className="mt-4 font-semibold">- John D.</p>
            </div>
            <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
              <p className="text-gray-600 dark:text-gray-400">
                "As a non-native speaker, this platform has been invaluable in
                helping me master everyday English."
              </p>
              <p className="mt-4 font-semibold">- Maria L.</p>
            </div>
          </div>
        </div>

        {/* Mobile Compatibility */}
        <div className="mb-12 text-center sm:mb-16">
          <h2 className="mb-4 text-2xl font-bold text-gray-800 dark:text-gray-200 sm:text-3xl">
            Learn Anytime, Anywhere
          </h2>
          <p className="mb-6 text-lg text-gray-600 dark:text-gray-400 sm:mb-8 sm:text-xl">
            Access Language Explorer on any device - desktop, tablet, or mobile
          </p>
          <div className="flex justify-center">
            <img
              src="/path-to-mockup-image.png"
              alt="Mobile app mockup"
              className="w-full max-w-sm rounded-lg shadow-lg sm:max-w-md"
            />
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mb-12 sm:mb-16">
          <h2 className="mb-6 text-center text-2xl font-bold text-gray-800 dark:text-gray-200 sm:mb-8 sm:text-3xl">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            <details className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
              <summary className="cursor-pointer text-lg font-semibold">
                How often is new content added?
              </summary>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                We add new exercises and topics weekly to keep the content fresh
                and engaging.
              </p>
            </details>
            <details className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
              <summary className="cursor-pointer text-lg font-semibold">
                Is Language Explorer suitable for beginners?
              </summary>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Absolutely! We have content for all levels, from beginners to
                advanced learners.
              </p>
            </details>
            <details className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
              <summary className="cursor-pointer text-lg font-semibold">
                Can I track my progress over time?
              </summary>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Yes, our dashboard provides detailed insights into your learning
                journey and progress.
              </p>
            </details>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mb-12 rounded-lg bg-blue-600 p-6 text-center text-white sm:mb-16 sm:p-8">
          <h2 className="mb-4 text-2xl font-bold sm:text-3xl">
            Ready to Enhance Your Language Skills?
          </h2>
          <p className="mb-6 text-lg sm:mb-8 sm:text-xl">
            Join thousands of satisfied learners and start your journey today!
          </p>
          <Link
            to="/sign-up"
            className="inline-block rounded-full bg-white px-6 py-2 text-base font-semibold text-blue-600 transition duration-300 hover:bg-gray-100 sm:px-8 sm:py-3 sm:text-lg"
          >
            Get Started for Free
          </Link>
        </div>
      </div>

      <footer className="bg-gray-200 py-6 text-center dark:bg-gray-800 sm:py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <p className="mb-4 text-gray-600 dark:text-gray-400">
            © 2023 Language Explorer. All rights reserved.
          </p>
          <div className="flex flex-col items-center justify-center space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
            <Link to="/privacy" className="text-blue-600 hover:text-blue-800">
              Privacy Policy
            </Link>
            <Link to="/terms" className="text-blue-600 hover:text-blue-800">
              Terms of Service
            </Link>
            <Link to="/contact" className="text-blue-600 hover:text-blue-800">
              Contact Us
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
