import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import MCExercise from "./components/MCExercise";
import Navigation from "./components/Navigation";
import Dashboard from "./components/Dashboard";
import Exercises from "./components/Exercises";
import RejectAccess from "./components/RejectAccess";
import Leaderboard from "./components/Leaderboard";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import HomePage from "./components/HomePage";
import PrivateRoute from "./components/PrivateRoute";
import Warning from "./components/Warning";
import ScorePage from "./components/ScorePage";
import IdleTimerHandler from "./components/IdleTimerHandler";
import { LogoutContext } from "./components/LogoutContext";
import { ThemeProvider } from "./context/ThemeContext";
import axios from "axios";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Regex from "./components/Regex";
export const UserContext = createContext(null);
export const ExerciseContext = createContext(null);

const App = () => {
  const [user, setUser] = useState({
    email: localStorage.getItem("userEmail"),
    name: localStorage.getItem("userName"),
  });
  const [exercise_titles, setExerciseTitles] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true",
  );
  const [exercise] = useState(localStorage.getItem("exercise"));

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.clear();

    setUser({ email: "", name: "", "present-tense": "" });
  };

  useEffect(() => {
    const fetchExerciseTitles = async () => {
      try {
        // Fetch titles for exercises

        const titlesResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/titles`,
        );
        const uniqueTitles = titlesResponse.data.uniqueTitles;

        const allTitles = [];
        // Add uniqueTitles
        for (const title of uniqueTitles) {
          if (!allTitles.includes(title)) {
            allTitles.push(title);
          }
        }

        setExerciseTitles(uniqueTitles);
      } catch (error) {
        console.error("Error fetching exercise titles:", error);
      }
    };
    fetchExerciseTitles();
  }, []);

  return (
    <ThemeProvider>
      <LogoutContext.Provider value={handleLogout}>
        <UserContext.Provider
          value={{ user, setUser, isAuthenticated, setIsAuthenticated }}
        >
          <ExerciseContext.Provider value={exercise}>
            <Router>
              <IdleTimerHandler>
                <div className="flex min-h-screen flex-col bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100">
                  <Navigation
                    onLogin={handleLogin}
                    onLogout={handleLogout}
                    setUser={setUser}
                  />
                  <Routes>
                    <Route path="/regex" element={<Regex />} />
                    <Route path="/" element={<HomePage />} />
                    {/* <Route path="/regex" element={<Regex />} /> */}
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/leaderboard"
                      element={<Leaderboard exerciseTitles={exercise_titles} />}
                    />
                    {exercise_titles.map((title) => (
                      <Route
                        key={title}
                        path={`/${title}`}
                        element={
                          <PrivateRoute isAuthenticated={isAuthenticated}>
                            {" "}
                            <MCExercise exerciseTitle={title} />{" "}
                          </PrivateRoute>
                        }
                      />
                    ))}
                    <Route path="/score" element={<ScorePage />} />
                    <Route
                      path="/sign-in"
                      element={
                        <SignIn
                          onLogin={handleLogin}
                          onLogout={handleLogout}
                          setUser={setUser}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    />
                    <Route
                      path="/sign-up"
                      element={
                        <SignUp
                          onLogin={handleLogin}
                          setUser={setUser}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    />
                    <Route path="/exercises" element={<Exercises />} />
                    <Route path="/reject-access" element={<RejectAccess />} />
                    <Route path="/warning" element={<Warning />} />
                    <Route
                      path="/reset-password/:resetToken"
                      element={<ResetPassword />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />{" "}
                    {/* New Route */}
                  </Routes>
                </div>
              </IdleTimerHandler>
            </Router>
          </ExerciseContext.Provider>
        </UserContext.Provider>
      </LogoutContext.Provider>
    </ThemeProvider>
  );
};

export default App;
