import React, { useState, useRef } from "react";
import useOutsideClick from "../hooks/useOutsideClick";

const SettingsModal = ({ isOpen, onClose, user }) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [new_confirm_password, setNewConfirmPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState();
  const [passwordError, setPasswordError] = useState("");

  // Create a ref for the modal content
  const modalRef = useRef(null);
  useOutsideClick(modalRef, onClose);
  const handlePasswordChange = async (event) => {
    event.preventDefault(); // Prevent the default form submit action
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/changePassword`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_email: user.email,
          original_password: password,
          new_password: newPassword,
          confirm_password: new_confirm_password,
        }),
      },
    );
    const data = await response.json();
    if (data.message === "Password changed successfully.") {
      setPasswordChanged(true);
    } else {
      setPasswordChanged(false);
      setPasswordError(data.message);
    }
  };

  return (
    <>
      <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div
          ref={modalRef}
          className="rounded bg-white p-6 shadow-lg dark:bg-black"
        >
          <h2 className="mb-4 text-lg font-bold text-black dark:text-white">
            Change Password
          </h2>
          <div className="mb-4">
            <label
              htmlFor="originalPassword"
              className="mb-2 block text-black dark:text-white"
            >
              Original Password:
            </label>
            <input
              type="password"
              id="originalPassword"
              className="w-full rounded border border-gray-300 p-2 text-black"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="newPassword"
              className="mb-2 block text-black dark:text-white"
            >
              New Password:
            </label>
            <input
              type="password"
              id="newPassword"
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full rounded border border-gray-300 p-2 text-black"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmNewPassword"
              className="mb-2 block text-black dark:text-white"
            >
              Confirm New Password:
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              onChange={(e) => setNewConfirmPassword(e.target.value)}
              className="w-full rounded border border-gray-300 p-2 text-black"
            />
            {passwordError && (
              <p className="text-xs italic text-red-500">{passwordError}</p>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mr-2 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              onClick={handlePasswordChange}
            >
              Submit
            </button>
            <button
              onClick={onClose}
              className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {passwordChanged && (
        <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={modalRef} className="rounded bg-white p-6 shadow-lg">
            <h1>Successful change of password</h1>
            <button
              onClick={onClose}
              className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsModal;
