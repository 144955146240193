import React from "react";
import { Link } from "react-router-dom";
const NavLink = ({ to, label, onClick }) => {
  return onClick ? (
    <button onClick={onClick} className="mr-[16px] ml-[16px]">
      {label}
    </button>
  ) : (
    <Link to={to} className="mr-[16px] ml-[16px]">
      {label}
    </Link>
  );
};

export default NavLink;
