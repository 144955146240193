import { useEffect } from "react";

const useOutsideClick = (ref, callback) => {
  // ref: The ref of the elements you want to protect from outside clicks. The hook will detect clicks outside of these elements.
  // callback: The function to call when a click outside the specified elements is detected.

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
