import React, { useEffect, useRef, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./../App";

export const IdleTimerContext = createContext();

const IdleTimerHandler = ({ children }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(UserContext);
  const idleTimerRef = useRef(null);

  const resetIdleTimer = () => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }

    idleTimerRef.current = setTimeout(() => {
      navigate("/warning");
    }, 30 * 60 * 1000);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const handleActivity = () => {
      resetIdleTimer(); // Reset the idle timer when the user interacts
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    resetIdleTimer(); // Reset the idle timer when the component mounts

    return () => {
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [isAuthenticated]);

  return (
    <IdleTimerContext.Provider value={resetIdleTimer}>
      {children}
    </IdleTimerContext.Provider>
  );
};

export default IdleTimerHandler;
