import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../App";
import SignUpWindow from "./SignUpWindow";
import NavLink from "./NavLink";
import { ThemeContext } from "./../context/ThemeContext";
import logo from "./../assets/logo.png"; // Import the logo image

const Navigation = ({ onLogin, onLogout, setUser }) => {
  const { isAuthenticated } = useContext(UserContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [slideMenu, setSlideMenu] = useState(false);
  const [signupWindow, setSignupWindow] = useState(false);

  const location = useLocation();
  React.useEffect(() => {
    const hideSlideMenuPaths = [
      "/regex",
      "/exercises",
      "/leaderboard",
      "/dashboard",
      "/sign-in",
      "/sign-up",
    ];

    if (hideSlideMenuPaths.includes(location.pathname)) {
      setSlideMenu(false);
    }
  }, [location]);

  const closeSlideMenu = () => {
    setSlideMenu(false);
  };
  const openSlideMenu = () => {
    setSlideMenu(true);
  };

  const closeSignUp = () => {
    setSignupWindow(false);
  };

  const navItems = [
    { label: "Tagger", to: "/regex" },
    { label: "Exercises", to: "/exercises" },
    { label: "Leaderboard", to: "/leaderboard" },
    ...(!isAuthenticated
      ? [
          { label: "Sign In", to: "/sign-in" },
          { label: "Sign Up", to: "/sign-up" },
        ]
      : [
          { label: "Dashboard", to: "/dashboard" },
          { label: "Logout", onClick: onLogout },
        ]),
  ];

  return (
    <div>
      <div className="flex items-center border-b-2 border-gray-200 bg-gray-200 dark:bg-gray-300 dark:text-gray-800">
        <Link to="/" className="text-2xl no-underline md:text-4xl">
          <img
            src={logo}
            alt="University English Mastery Adventures"
            className="h-12"
          />{" "}
          {/* Adjust the height as needed */}
        </Link>

        <div className="grow"></div>
        <div className="hidden md:block">
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.to}
              label={item.label}
              onClick={item.onClick}
            />
          ))}
        </div>
        <button
          onClick={toggleTheme}
          className="rounded bg-gray-800 px-4 py-2 text-white dark:bg-gray-700 dark:text-lightGray"
        >
          Toggle to {theme === "light" ? "Dark" : "Light"} Mode
        </button>

        {slideMenu ? (
          <div className="relative pr-[10px] pt-[40px] shadow-lg md:hidden">
            <button
              className="absolute right-0 top-0 text-4xl"
              onClick={closeSlideMenu}
            >
              &times;
            </button>
            <ul className="space-y-4">
              {navItems.map((item, index) => (
                <li key={index}>
                  {item.onClick ? (
                    <span onClick={item.onClick} className="cursor-pointer">
                      {item.label}
                    </span>
                  ) : (
                    <Link to={item.to}>{item.label}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <span
            className="relative cursor-pointer text-6xl md:hidden"
            onClick={openSlideMenu}
          >
            &#x2630;
          </span>
        )}
      </div>
      {signupWindow && (
        <SignUpWindow
          closeSignUp={closeSignUp}
          onLogin={onLogin}
          setUser={setUser}
        />
      )}
    </div>
  );
};

export default Navigation;
