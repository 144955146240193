import { useLocation } from "react-router-dom";

const ScorePage = () => {
  const location = useLocation();
  const { marks, results } = location.state;
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Your Score</h1>
      <p className="mb-6 text-lg">
        You scored {marks} out of {results.length}
      </p>
      <ul className="space-y-6">
        {results.map((result, index) => (
          <div key={index}>
            <li
              className={`p-4 rounded-lg shadow-md text-black ${
                result.isCorrect ? "bg-green-100" : "bg-red-100"
              }`}
            >
              Question {result.question_id}
            </li>
            {result.options.map((option) => (
              <li key={option.label}>
                {option.label}. {option.text}
              </li>
            ))}
            <li>
              {result.isCorrect ? (
                <p className="text-green-500">
                  Your answer: &#x2713; {result.userAnswer}
                </p>
              ) : (
                <p className="text-red-500">
                  Your answer: &times; {result.userAnswer}
                </p>
              )}
              <p className="text-green-500">
                Correct answer: &#x2713; {result.correctAnswer}
              </p>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ScorePage;
