import React, { useState, useEffect } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";

const Leaderboard = ({ exerciseTitles }) => {
  const [questionCount, setQuestionCount] = useState({});
  const [exercises, setExercises] = useState(exerciseTitles);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [topUsers, setTopUsers] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [leaderboardError, setLeaderboardError] = useState(null);
  const formatExerciseTitle = (title) => {
    if (!title) return ""; // Return empty string if title is undefined
    return title
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (exerciseTitles && exerciseTitles.length > 0) {
      setExercises(exerciseTitles);
    }
  }, [exerciseTitles]);

  useEffect(() => {
    if (selectedExercise) {
      setLoadingLeaderboard(true);
      setLeaderboardError(null);

      const socket = socketIOClient(process.env.REACT_APP_SOCKET_IO_ENDPOINT);
      socket.emit("requestLeaderboard", selectedExercise);

      socket.on("updateNewLeaderboard", (data) => {
        if (Array.isArray(data)) {
          setTopUsers(data);
        }
      });

      socket.on("lastUpdate", (data) => {
        setLastUpdated(data);
        setLoadingLeaderboard(false);
      });

      socket.on("error", (error) => {
        setLeaderboardError("Failed to load leaderboard. Please try again.");
        setLoadingLeaderboard(false);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [selectedExercise]);

  useEffect(() => {
    const fetchNumberOfQuestions = async () => {
      try {
        const counts = {};

        for (const title of exerciseTitles) {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/fetch-number-of-questions/${title}`,
          );
          counts[title] = response.data.numberOfQuestions;
        }
        setQuestionCount(counts);
      } catch (error) {
        console.error("Error fetching the number of questions:", error);
      }
    };

    if (exerciseTitles && exerciseTitles.length > 0) {
      fetchNumberOfQuestions();
    }
  }, [exerciseTitles]);

  return (
    <div className="min-h-screen bg-gray-100 p-6 dark:bg-gray-800">
      {/* Header */}
      <h1 className="mb-8 text-center text-3xl font-bold text-gray-800 dark:text-gray-100">
        Leaderboard
      </h1>

      {/* Exercise Selection Buttons */}
      <div className="mb-10 flex flex-col justify-center gap-4 sm:flex-row">
        {exercises.map((exercise) => (
          <button
            key={exercise}
            className={`rounded-lg px-6 py-3 font-semibold text-gray-200 transition-colors duration-200 ${
              selectedExercise === exercise
                ? "bg-blue-600 dark:bg-blue-500"
                : "dark:hover:bg-blue-500k bg-blue-500 hover:bg-blue-600 dark:bg-blue-400"
            }`}
            onClick={() => setSelectedExercise(exercise)}
          >
            {formatExerciseTitle(exercise)}
          </button>
        ))}
      </div>

      {/* Leaderboard Section */}
      {selectedExercise && (
        // <div className="mx-auto max-w-4xl rounded-lg bg-white p-6 shadow-lg dark:bg-gray-700">
        <div className="flex min-w-full flex-col items-center">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
            {formatExerciseTitle(selectedExercise)} Leaderboard
          </h2>

          {/* Loading State */}
          {loadingLeaderboard && (
            <p className="text-center text-gray-600 dark:text-gray-300">
              Loading leaderboard...
            </p>
          )}

          {/* Error State */}
          {leaderboardError && (
            <div className="mb-4 rounded bg-red-100 p-4 text-red-700 dark:bg-red-200 dark:text-red-900">
              {leaderboardError}
            </div>
          )}

          {/* Leaderboard List */}
          {!loadingLeaderboard && !leaderboardError && topUsers.length > 0 && (
            <ul className="m-0 w-full max-w-4xl list-none p-0">
              {topUsers.map((user, index) => (
                <li
                  key={user._id}
                  className="flex justify-between border-b border-gray-200 p-4 transition-colors duration-200 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-600"
                >
                  <span className="text-lg font-medium text-gray-800 dark:text-gray-100">
                    {index + 1}. {user.user_name}
                  </span>
                  <span className="text-gray-600">
                    {user.scores && user.scores[selectedExercise] !== undefined
                      ? `${user.scores[selectedExercise]}/${
                          questionCount[selectedExercise] || "N/A"
                        } (${(
                          (user.scores[selectedExercise] /
                            (questionCount[selectedExercise] || 1)) *
                          100
                        ).toFixed(1)}%)`
                      : "No score"}
                  </span>
                </li>
              ))}
            </ul>
          )}
          <div>{lastUpdated && <p>Last Updated at: {lastUpdated}</p>}</div>
          {/* No Users Found */}
          {!loadingLeaderboard &&
            !leaderboardError &&
            topUsers.length === 0 && (
              <p className="text-center text-gray-600 dark:text-gray-300">
                No users found for this exercise.
              </p>
            )}
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
