import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IdleTimerContext } from "./IdleTimerHandler";
import { LogoutContext } from "./LogoutContext"; // Import LogoutContext

const Warning = () => {
  const navigate = useNavigate();
  const resetIdleTimer = useContext(IdleTimerContext);
  const handleLogout = useContext(LogoutContext); // Use useContext to get handleLogout
  const warningTimeoutRef = useRef(null);

  useEffect(() => {
    // Set a timer to log out the user after 10 seconds
    warningTimeoutRef.current = setTimeout(() => {
      handleLogout();
      navigate("/");
    }, 10 * 1000);

    // Cleanup the timer if the component unmounts or user interacts
    return () => {
      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }
    };
  }, []);

  const handleStayLoggedIn = () => {
    if (warningTimeoutRef.current) {
      clearTimeout(warningTimeoutRef.current);
    }
    resetIdleTimer(); // Reset the idle timer when staying logged in
    navigate("/"); // Redirect to home or previous page
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="max-w-sm rounded-lg bg-white p-8 text-center shadow-lg">
        <p className="mb-4 text-gray-700">
          You will be logged out due to inactivity in 10 seconds. Please click
          the button to stay logged in.
        </p>
        <button
          onClick={handleStayLoggedIn}
          className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white transition duration-150 ease-in-out hover:bg-blue-700 focus:outline-none"
        >
          I am here!
        </button>
      </div>
    </div>
  );
};

export default Warning;
