import React, { useState } from "react";
import SignInWindow from "./SignInWindow";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const RejectAccess = ({ onLogin, onLogout, isAuthenticated, setUser }) => {
  const [signInWindow, setSignInWindow] = useState();
  const navigate = useNavigate();

  const showSignIn = () => {
    setSignInWindow(true);
  };
  const closeSignIn = () => {
    setSignInWindow(false);
  };

  const goToExercises = () => {
    navigate("/sign-in", {});
  };
  const goToSignUp = () => {
    navigate("/sign-up");
  };
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="text-center">
        <div className="access-denied-message">
          <h1 className="text-6xl font-bold">Access Denied</h1>

          <p className="mt-4 text-xl text-gray-500">
            You must be logged in to view this page.
          </p>
          <div className="mt-6">
            <button
              onClick={goToExercises}
              className="rounded bg-blue-500 px-6 py-3 text-lg text-white transition duration-300 hover:bg-blue-700"
            >
              Log In
            </button>
            <button
              onClick={goToSignUp}
              className="ml-4 rounded bg-blue-500 px-6 py-3 text-lg text-white transition duration-300 hover:bg-blue-700"
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectAccess;
