import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import InputField from "./InputField";

const SignIn = ({ onLogin, setUser, isAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidLogin, setIsValidLogin] = useState(false);
  const [loginFailedMessage, setLoginFailedMessage] = useState("");
  const [countdown, setCountdown] = useState(3);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !isValidLogin) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, isValidLogin, navigate]);

  useEffect(() => {
    let timer;
    if (isValidLogin && countdown > 0) {
      timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
    } else if (isValidLogin && countdown === 0) {
      setIsLoggingIn(false);
      navigate(from === "/exercises" ? "/exercises" : "/dashboard");
    }
    return () => clearTimeout(timer);
  }, [isValidLogin, countdown, navigate, from]);

  const handleSignIn = async (event) => {
    event.preventDefault();
    setIsLoggingIn(true);
    console.log("Attempting to sign in");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_email: email, password: password }),
        },
      );

      const data = await response.json();
      console.log("Response status:", response.status);
      console.log("Response data:", data);

      if (response.ok) {
        console.log("Login successful, setting state");
        onLogin(); // Set isAuthenticated to true immediately
        setUser({ email: data.user_email, name: data.user_name });
        localStorage.setItem("userEmail", data.user_email);
        localStorage.setItem("userName", data.user_name);
        setIsValidLogin(true);
        console.log("isValidLogin set to true");
      } else {
        console.log("Login failed:", data.message);
        setLoginFailedMessage(data.message);
        setIsValidLogin(false);
        setIsLoggingIn(false);
      }
    } catch (error) {
      console.error("Error during sign in:", error);
      alert("Network error during sign in");
      setIsLoggingIn(false);
    }
  };

  return (
    <div className="signup-div">
      {!isValidLogin && loginFailedMessage && (
        <div
          className="display-login-reject mb-4 border-l-4 border-red-500 bg-red-100 p-4 text-red-700"
          role="alert"
        >
          <h1 className="text-lg font-semibold">{loginFailedMessage}</h1>
        </div>
      )}
      {isValidLogin && (
        <div
          className="mb-4 rounded border-l-4 border-green-500 bg-green-100 p-4 text-green-700 shadow-md"
          role="alert"
        >
          <h1 className="text-lg font-semibold">
            Login successful! <br />
            Directing you to Dashboard/Exercises in {countdown} seconds...
          </h1>
        </div>
      )}

      <div className="flex w-[100%] items-center">
        <div className="signup-label grow text-center">Sign In</div>
      </div>
      {/* Always render the form but disable it when isValidLogin is true */}
      <form className="form-div" onSubmit={handleSignIn}>
        <label htmlFor="email">Email:</label>
        <InputField
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isValidLogin || isLoggingIn}
        />
        <label htmlFor="password">Password:</label>
        <InputField
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={isValidLogin || isLoggingIn}
        />
        <button
          type="submit"
          className={`rounded bg-blue-500 px-4 py-2 font-bold text-white shadow-lg transition duration-300 hover:bg-blue-700 hover:shadow-xl ${
            isLoggingIn ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isLoggingIn || isValidLogin}
        >
          {isLoggingIn ? "Logging in..." : "Sign In"}
        </button>
        <Link to="/forgot-password" className="mt-4">
          Forgot Password?
        </Link>
      </form>
    </div>
  );
};

export default SignIn;
